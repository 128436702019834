import React, { useState } from "react"
import Layout2 from "../components/Layout2"
import Seo from "../components/Seo"
import axios from "axios";
import { Helmet } from "react-helmet";
import Recaptcha from "react-recaptcha";

const Contact = () => {


  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mob, setMob] = useState("");
  const [loading, setloading] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [captchamsg, setCaptchamsg] = useState("");
  const [button1, setbutton1] = useState(true);
  // const button1="enabled";

  console.log(captchamsg);

  const handleSubmit = (event) => {
    if (isVerified) {
      event.preventDefault();
      const postData = { username, email, message, mob };

      axios.post('../.netlify/functions/contactform', postData, setloading(true), setbutton1(true)).then(response => {
        window.location.replace("../thankyou");
        // this.setState({message:"User created successfuly."})
      }).catch(error => {
        console.log(error);
        // alert(error);
      });
    }
    else {
      console.log("please fill captcha");
    }
  }

  const inputEvent = (event) => {

    setUserName(event.target.value);

  }
  const inputEvent2 = (event) => {

    setEmail(event.target.value);
  }

  const inputEvent3 = (event) => {

    setMessage(event.target.value);
  }
  const inputEvent4 = (event) => {

    setMob(event.target.value);
  }

  const callback = function () {
    console.log('Done!!!!');
  }

  const verifyCallback = function () {
    setIsVerified(true);
    setbutton1(false);
  }
  const expiredCallback = function () {
    setbutton1(true);

  }
  return (
    <Layout2>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer></script>
      </Helmet>

      <Seo title="Contact" description="MP2IT CONTACTUS CONTACT MP2ITHOME MP RESEARCH WORK MPRESEARCHWORK MPBS MP BUSINESS SOLUTION MPRW" />
      <div className="contact-page">
        <section className="form_section" style={{ paddingTop: "40px" }}>
          <div style={{ color: "#2d9dd3", paddingBottom: "10px" }}>
            <h3>{loading ? ("Please Wait...") : ("")}
            </h3>
          </div>
          <article className="contact-form" data-sal="slide-up" data-sal-duration="1000">
            <h3>get in touch</h3>

            <form name="contact-form" onSubmit={handleSubmit}>
              {/* <input type="hidden" name="form-name" value="contact-form" /> */}
              <div className="form-group">
                <input type="text" placeholder="name" className="form-control" value={username} onChange={inputEvent} required />
                <input type="email" placeholder="email" className="form-control" value={email} onChange={inputEvent2} />
                <input type="text" placeholder="contact No." className="form-control" value={mob} onChange={inputEvent4} required minLength="10" maxLength="13" />
                <textarea name="message" rows="5" placeholder="message" className="form-control" value={message} onChange={inputEvent3} required></textarea>

                <Recaptcha
                  sitekey='6Ldc1JUbAAAAAJA9gXfFpFWBrmhKE4dT9sxlGDKc'
                  render="explicit"
                  onloadCallback={callback}
                  verifyCallback={verifyCallback}
                  expiredCallback={expiredCallback}
                />
              </div>
              <button type="submit" className="submit-btn btn" disabled={button1}>submit here</button>

            </form>

          </article>

        </section>

        <section className="Address_section" style={{ paddingTop: '40px' }}>
          <article data-sal="slide-up" data-sal-duration="1000">
            <div className="">
              <h3>Address</h3>
              <h4>
                SM Tower Plot no.13,<br />
                Saraswati kund, Near National<br />
                Chamber, Masani Bypass Road,<br />
                Mathura (U.P.)-281003
              </h4>
            </div>
          </article>


        </section>
        {/* <section className="Address_section">
          
        </section> */}
      </div>
    </Layout2 >
  )
}

export default Contact